.Footer {
  background-color: rgb(43, 82, 147);
  color: var(--color-bg);
  height: 150px;
  display: flex;
  font-family: var(--font-family);
  padding: 20px;
  font-size: 0.8em;
  align-items: center;
}

.Footer-social {
  position: absolute;
  left: 85vw;
  display: flex;
  flex-direction: row;
}
.Footer-social img {
  width: 30px;
  margin: 1rem;
}

.Footer-contact {
  margin-right: 2rem;
  width: 200px;
}

.Footer-contact_container {
  display: flex;
  flex-direction: row;
  align-items: start;
  margin-left: 3rem;
}

@media screen and (max-width: 1230px) {
  .Footer {
    font-size: 0.7em;
  }
}
@media screen and (max-width: 1000px) {
  .Footer-social {
    left: 80vw;
  }
}
@media screen and (max-width: 750px) {
  .Footer-social {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .Footer {
    font-size: 0.4em;
  }
  .Footer-contact {
    margin-right: 1rem;
    width: 100px;
  }
  .Footer-contact_container {
    margin-left: 0;
  }
}
