.Incentives {
  display: flex;
  font-family: var(--font-family);
  margin-bottom: 5rem;
  min-height: 80vh;
}

.Incentives-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
}

.Incentives-content h1 {
  color: var(--color-text);
  font-size: 2.5em;
  font-weight: 450;
}

.Incentives-desc-container {
  display: flex;
  flex-direction: row;
  align-items: start;
}

.Incentives-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  margin-right: 4rem;
  margin-left: 4rem;
}

.Incentives-desc h2 {
  color: var(--color-text);
  margin-top: 1rem;
  font-size: xx-large;
  font-weight: 1;
}

.Incentives-desc p {
  color: var(--color-text2);
  margin-top: 1rem;

  font-weight: 10;
  max-width: 30rem;
  width: 100%;
}

.Incentives-desc h3 {
  margin-top: 3rem;
}

@media screen and (max-width: 1050px) {
  .Incentives {
    margin: 0 3rem 0 3rem;
  }

  .Incentives-desc {
    text-align: center;
    margin: 2rem 0 2rem 0;
  }

  .Incentives-desc-container {
    margin: 1rem 0 0 0;
    flex-direction: column;
  }
  .Incentives-desc h2 {
    font-size: 1.7em;
  }
}

@media screen and (max-width: 650px) {
  .Services {
    text-align: center;
  }
  .Services-prices-container {
    flex-direction: column;
  }
  .Services-prices-item {
    margin: 1rem 0rem 1rem 0rem;
  }
  .Services-prices h1 {
    margin-bottom: 0;
  }
}
