.Contact {
  display: flex;
  font-family: var(--font-family);
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem 0 5rem 0;
  min-height: 80vh;
}
.Contact iframe {
  width: 400px;
  height: 250px;
}

.Contact-content {
  margin-top: 3rem;
  display: flex;
  flex-direction: row;
  align-items: start;
}

.Contact h1 {
  color: var(--color-text);
  font-size: 2.5em;
  font-weight: 450;
  margin: 0 3rem 0 3rem;
}

.Contact-desc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10rem;
}

.Contact-desc {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 3rem;
}

.Contact-desc h2 {
  color: var(--color-text);
  font-weight: 250;
  margin-bottom: 1rem;
}

.Contact-desc p {
  color: var(--color-text2);
  font-weight: 10;
}

.Contact-address {
  align-items: flex-start;
  margin-bottom: 1rem;
}

.Contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Contact-form textarea {
  resize: none;
  border-width: 0.1rem;
  border-color: black;
  border-style: solid;
  width: 400px;
  height: 200px;
  padding: 10px;
  font-size: 15px;

  resize: none;
  font-family: "Arial";
}
.Contact-form_button {
  align-items: center;
  background-color: rgb(43, 82, 147);

  color: rgb(255, 255, 255);
  font-family: var(--font-family);
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  color: var(--color-bg);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin-top: 1rem;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 170px;
  height: 10px;
}

.Contact-form_button:hover,
.Contact-form_button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(255, 255, 255, 0.65);
}

.Contact-form_button:hover {
  transform: translateY(-1px);
}

.Contact-form_button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(255, 255, 255, 0.65);
  transform: translateY(0);
}
.Contact-form h2 {
  font-size: 1.8em;
  font-weight: 300;
  margin: 0 0 1rem 0;
}

.Contact-form h3 {
  color: var(--color-text);
  font-weight: 250;
  margin-bottom: 1rem;
}
.Contact-form_info {
  display: flex;
  flex-direction: column;
}

.Contact-form_input {
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem 1rem 1rem;
}

.Contact-input,
.Contact-input_email {
  border-width: 0.1rem;
  border-color: black;
  border-style: solid;

  width: 200px;
  height: 40px;
  padding: 10px;
  font-size: 15px;
  margin-right: 2rem;
}

@media screen and (max-width: 1350px) {
  .Contact-content {
    flex-direction: column;
  }
  .Contact-desc-container {
    margin: 0;
  }
  .Contact-content {
    align-items: center;
  }
  .Contact iframe {
    width: 450px;
    height: 350px;
  }

  .Contact-form {
    margin-top: 3rem;
  }
  .Contact-form_info {
    align-items: center;
  }
  .Contact-input_email {
    width: 400px;

    margin: 0;
  }
  .Contact-input {
    margin: 0;
  }
}
@media screen and (max-width: 1050px) {
  .Contact-content {
    flex-direction: column;
  }
  .Contact-desc-container {
    margin: 0;
  }
  .Contact iframe {
    width: 500px;
    height: 400px;
  }
}

@media screen and (max-width: 800px) {
  .Contact {
    text-align: center;
  }
  .Contact iframe {
    width: 450px;
    height: 350px;
  }
}

@media screen and (max-width: 500px) {
  .Contact iframe {
    width: 300px;
    height: 250px;
  }
  .Contact-input {
    width: 175px;
    height: 30px;
    font-size: 13px;
  }

  .Contact-input_email {
    width: 350px;
    height: 30px;
    font-size: 13px;
  }
  .Contact-form textarea {
    width: 350px;
    height: 175px;
    font-size: 13px;
  }
}

@media screen and (max-width: 430px) {
  .Contact iframe {
    width: 250px;
    height: 200px;
  }
  .Contact-input,
  .Contact-input_email {
    margin: 0;
  }

  .Contact-input {
    width: 150px;
    height: 27px;
    font-size: 12px;
  }

  .Contact-input_email {
    width: 300px;
    height: 27px;
    font-size: 12px;
  }
  .Contact-form textarea {
    width: 300px;
    height: 150px;
    font-size: 13px;
  }
}
