.message-container {
  position: relative;
  width: 100%; /* Adjust as needed */
  height: 50px; /* Adjust to fit the height of your messages */
  overflow: hidden; /* Hide the messages when they move out of the frame */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(43, 82, 147);
  font-family: var(--font-family);
  font-weight: 100;
  color: var(--color-bg);
}
.message {
  position: absolute;
  opacity: 0; /* Start hidden */
  transform: translateX(-150%); /* Start farther off-screen to the left */
  animation: slide 15s infinite; /* Repeat every 15 seconds */
}

.message:nth-child(1) {
  animation-delay: 0s; /* Start immediately */
}

.message:nth-child(2) {
  animation-delay: 5s; /* Start after the first message */
}

.message:nth-child(3) {
  animation-delay: 10s; /* Start after the second message */
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateX(-150%); /* Start off-screen to the left */
  }
  10% {
    opacity: 1;
    transform: translateX(0); /* Enter and center the message */
  }
  30% {
    opacity: 1;
    transform: translateX(0); /* Stay visible in the center */
  }
  40% {
    opacity: 0;
    transform: translateX(150%); /* Exit off-screen to the right */
  }
  100% {
    opacity: 0; /* Remain hidden */
  }
}

.Header {
  font-weight: 100;
}

.Header-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 3rem;
  margin-right: 5rem;
}

.Header-content h1 {
  font-family: var(--font-family);
  font-weight: 100;
  color: var(--color-text);
}

.Header-content h2 {
  font-family: var(--font-family);
  font-weight: 10;
  color: var(--color-text);
  margin-top: 3rem;
}

.Header-content img {
  margin-top: 4rem;
  height: 100%;
  width: 100%;
  max-width: 680px;
  max-height: 300px;
}

.Header-content p {
  flex-direction: column;
  margin-left: 10rem;
  margin-top: 6rem;
}

.Header-button {
  align-items: center;
  background-color: rgb(43, 82, 147);
  color: rgb(255, 255, 255);
  font-family: var(--font-family);
  border: none;

  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  color: var(--color-bg);
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin-top: 1rem;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 290px;
}

.Header-button:hover,
.Header-button:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(255, 255, 255, 0.65);
}

.Header-button:hover {
  transform: translateY(-1px);
}

.Header-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(255, 255, 255, 0.65);
  transform: translateY(0);
}

.Header-reviews {
  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: column;

  font-family: var(--font-family);
  color: var(--color-text);
  margin-bottom: 10rem;
}

.Header-reviews p {
  max-width: 75rem;
  margin: 3rem 2rem 0rem 2rem;
  font-size: large;
  line-height: 3rem;
}
.Header-reviews h2 {
  margin: 2rem 2rem 3rem 2rem;
  font-size: 2.5em;
  font-weight: 450;
  margin-top: 4rem;
}

.Header-about {
  display: flex;

  justify-content: center;
  align-items: center;

  flex-direction: column;
  margin-top: 15rem;
  /* margin-left: 10rem;
  margin-right: 10rem; */
  font-family: var(--font-family);
  color: var(--color-text);
}

.Header-about p {
  max-width: 75rem;
  margin: 3rem 2rem 0rem 2rem;
  font-size: large;
  line-height: 3rem;
}

.Header-about h2 {
  margin: 2rem 0 0 0;
  font-size: 2.5em;
  font-weight: 450;
  margin-top: 4rem;
}

@media screen and (max-width: 1300px) {
  .Header-content {
    align-items: center;
    flex-direction: column;
    margin-right: 0rem;
  }

  .Header-about p {
    max-width: 75rem;
    margin: 3rem 3rem 0rem 3rem;
    font-size: large;
    text-align: center;
  }
  .Header-content img {
    margin-top: 1rem;
  }
  .Header-content p {
    margin-left: 3rem;
  }
}

@media screen and (max-width: 650px) {
  .Header-about h1 {
    font-size: 2em;
  }

  .Header-content p {
    margin-right: 2.4rem;
  }
}
