.About {
  display: flex;
  font-family: var(--font-family);
  min-height: 80vh;
}

.About-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 3rem;
}

.About-content h1 {
  color: var(--color-text);
  font-size: 2.5em;
  font-weight: 450;
}

.About-content h2 {
  color: var(--color-text);
  margin-top: 4rem;
}
.About-content p {
  max-width: 40rem;
  margin-top: 2rem;
  font-size: large;
}
.About-desc {
  display: flex;
  flex-direction: row;
}
.About-desc img {
  margin-top: 4rem;
  width: 100%;
  height: 100%;
  max-width: 22rem;
  max-height: 25rem;
}
.About-desc_container {
  flex-direction: column;
  margin-left: 7rem;
}

@media screen and (max-width: 1050px) {
  .About-desc {
    align-items: center;
    flex-direction: column;
    margin-right: 0rem;
  }

  .About-desc_container {
    margin: 0;
    text-align: center;
  }
}

@media screen and (max-width: 650px) {
  .About-content {
    text-align: center;
  }
  /* .About-content h1 {
    font-size: 2em;
  }
  .About-content h2 {
    font-size: 1.3em;
  }
  .About-content p {
    font-size: 1em;
  } */
}
