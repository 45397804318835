.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-bg);
  padding: 2rem 6rem;

  position: sticky;
  top: 0;
  z-index: 1000; /* Makes sure the navbar stays on top of other content */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05); /* Optional: adds a shadow for separation */
}

.Navbar-social img {
  width: 30px;
  margin-left: 18px;
}

.Navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Navbar-logo img {
  width: 90px;
  height: 64px;
}

.Navbar-logo_large img {
  width: 290px;
  height: 70px;
  margin: 0 2rem 0 1rem;
}

.Navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.Navbar-links_container {
  display: flex;
  flex-direction: row;
}

.Navbar-menu_container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  text-align: end;
  background: rgb(236, 236, 236);
  padding: 2rem;
  position: absolute;
  right: 0;
  top: 40px;
  margin-top: 1rem;
  min-width: 210px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.Navbar-links_container p,
.Navbar-menu_container p {
  color: rgb(43, 82, 147);
  font-family: var(--font-family);
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.text-pop-up-top:hover {
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc,
      0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc,
      0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc,
      0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc,
      0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

@media screen and (max-width: 1150px) {
  .Navbar-logo_large {
    display: none;
  }
  .Navbar-logo {
    margin-right: 3rem;
  }
}
@media screen and (max-width: 1050px) {
  .Navbar-links_container {
    display: none;
  }
  .Navbar-logo {
    margin-right: 0;
  }

  .Navbar-menu {
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .Navbar {
    padding: 2rem 4rem;
  }
}

@media screen and (max-width: 550px) {
  .Navbar {
    padding: 2rem;
  }

  .Navbar-menu_container {
    top: 20px;
  }
}
