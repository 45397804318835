@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

:root {
  --font-family: "Poppins", sans-serif;

  --color-bg: rgb(239, 239, 239);
  --color-text: rgb(31, 31, 31);
  --color-text2: rgb(57, 57, 57);
  --color-bg2: rgb(43, 82, 147);
}
